import React         from 'react';
import { PageProps } from 'gatsby';

const NotFound: React.FC<PageProps> = () => (
    <main>
        <p>Sorry, page not found!</p>
    </main>
);

export default NotFound;
